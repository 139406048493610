
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
  import { Auto } from '@/entities/public'
  import { Query } from '@/entities/public/Resource/interfaces'
  import { formFilter } from '@/graphql/generated-types'
  import { mapActions } from 'vuex'

@Component({
  methods: {
    ...mapActions('resources/form', ['fetchData']),
  },
})
  export default class StockCharacteristics extends Vue {
  @Prop({ type: [Object, Array, Number, String] }) readonly item!: any;
  fetchData!: (payload: {
    query: Query
    filter?: formFilter
    offset?: number
    limit?: number
    force?: boolean
    distinct?: Array<string>
  }) => Promise<any>;

  characteristic = ''

  // Methods
  async getGeneration (version, year) {
    return this.fetchData({
      query: { name: 'findLite', model: 'Generation' },
      filter: { version_year: { id_version: { _eq: version }, id_year: { _eq: year } } },
    })
  }

  async findAlternativesAttributes (auto) {
    if (!auto?.version?.version?.id || !auto?.version?.year?.id) return

    let getGenerations = await this.getGeneration(auto?.version?.version?.id, auto?.version?.year?.id)

    let generation = getGenerations?.length === 1 ? getGenerations[0] : getGenerations.find(generation => generation.id === auto.generation?.id)

    if (generation?.attributes?.length) {
      return generation?.attributes
    }

    const attributes = await this.fetchData({
      query: {
        name: 'find',
        model: 'Attribute',
      },
      filter: {
        _and: [
          { id_version_year: { _eq: auto.version.id } },
          { component: { slug: { _in: ['bodywork', 'fuel', 'transmission', 'traction'] } } },
        ],
      },
    })

    if (attributes?.length) {
      return attributes
    }

    if (!auto?.generation?.sku) {
      return
    }

    let cont = 1
    while (cont <= 3 && !generation?.attributes?.length) {
      getGenerations = await this.getGeneration(auto?.version?.version?.id, auto?.version?.year?.id - cont)

      generation = getGenerations?.length === 1 ? getGenerations[0] : getGenerations.find(generation => generation.sku === auto.generation?.sku)

      if (generation?.attributes?.length) {
        return generation?.attributes || []
      } else {
        cont++
      }
    }

    return generation?.attributes || []
  }

  // Getters

  // Watchers
  @Watch('item', { immediate: true, deep: true })
  async onAutoChange (val: Auto) {
    if (!val?.id) return
    const attrs = await this.findAlternativesAttributes(val)

    if (attrs?.length) {
      const findFuel = attrs.find(attr => attr.component.slug === 'fuel')
      const fuel = findFuel?.id ? `/${findFuel?.componentValue?.value}` : ''

      const findTransmission = attrs.find(attr => attr.component.slug === 'transmission')
      const transmission = findTransmission?.id ? `/${findTransmission?.componentValue?.value}` : ''

      const findTraction = attrs.find(attr => attr.component.slug === 'traction')
      const traction = findTraction?.id ? `/${findTraction?.componentValue?.value}` : ''

      const findBodywork = attrs.find(attr => attr.component.slug === 'bodywork')
      const bodywork = findBodywork?.id ? `${findBodywork?.componentValue?.value}` : ''

      this.characteristic = `${bodywork}${fuel}${transmission}${traction}`
    }
  }
  }
